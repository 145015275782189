body {
  margin: 0;
}

.vehicle-list-wrapper.MuiBox-root::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.vehicle-list-wrapper.MuiBox-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px transparent;
  border: solid 3px transparent;
}

/* Handle */
.vehicle-list-wrapper.MuiBox-root::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #8e8ea1;
  border: solid 3px transparent;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background: #005c99;
}

.MuiTooltip-popper .MuiTooltip-arrow {
  color: #005c99;
}

#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_1-hour,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_1-day,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_5-days,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_1-week,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_1-month,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_3-months,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_6-months,
#speed_chrt_wrapper_AnnotationChart_zoomControlContainer_1-year {
  display: none;
}

.trip-item-map-fullscreen.leaflet-container {
  height: 100vh !important;
}
.trip-item-map-fullscreen.leaflet-container .leaflet-control-zoom,
.vehicle-item-map-fullscreen.leaflet-container .leaflet-control-zoom {
  right: 80px !important;
}
.vehicle-item-map-fullscreen.leaflet-container {
  min-height: 100vh !important;
}
